import openai from '../client';
import lastMessagesSlice from './slices/lastMessagesSlice';
import orgSlice from './slices/orgSlice';
import systemSlice from './slices/systemSlice';

/**
 * Generates a structured and optimized prompt for generating organizational reports.
 * @param {Object} params - The parameters for the prompt generation.
 * @param {string} params.userText - The user's input or question.
 * @param {Array<string>} params.tags - Tags relevant to the organizational context.
 * @param {Object} params.snapshot - A snapshot of the current organizational state.
 * @param {Array<Object>} params.lastMessages - Recent messages for context.
 * @returns {Object} - The generated prompt configuration.
 */
const reportsPrompt = async ({ userText, tags, snapshot, lastMessages }) => {
  // Generate the system description.
  const systemDescription = systemSlice();

  // Construct the organizational slice based on the snapshot and tags.
  const organizationalContext = orgSlice({ snapshot, tags });

  // Generate context from recent messages.
  const recentMessagesContext = lastMessagesSlice({ lastMessages });

  // Build the final prompt string.
  const promptString = `
${systemDescription}

## Reports

If the user is asking for information, generate a detailed, structured report that addresses their question. Avoid answering the question directly; instead, craft a report that provides insights and actionable recommendations.

Guidelines:
- **Clarity and Structure**: Ensure the report is clear, logically structured, and easy to read for upper management.
  - Use sections and subsections to organize the content effectively.
  - Include an executive summary for quick insights.
- **Relevance**: Address the specific question or focus area posed by the user. Avoid tangential information.
- **Actionable Recommendations**: Provide clear, practical steps or strategies that can be implemented.
  - Highlight potential risks and benefits for each recommendation.
  - Suggest specific metrics or KPIs for tracking progress.
- **Data-Driven Insights**: Base your analysis and suggestions on the provided organizational data.
  - Reference trends, patterns, or discrepancies from the snapshot or tags.
  - Avoid speculative or unsubstantiated claims.
- **Tone and Language**: Maintain a professional and objective tone.
  - Avoid jargon unless it is relevant and commonly understood by upper management.
  - Use concise language without sacrificing detail.
- **Handling Ambiguity**: If the question is unclear or irrelevant to organizational design:
  - Respond with: "I don't understand the question, can you rephrase it?"
  - Do not speculate or make assumptions.
- **Visual Aids**: Use tables, charts, or bullet points when appropriate to enhance readability.
  - Format tables in Grutatxt for consistency and compatibility.
- **Exclusions**: Ensure the report excludes any UUIDs, confidential identifiers, or unrelated keywords.

${organizationalContext}

The user has requested a report. Include your reasoning, offer actionable recommendations, and structure your response effectively.

${recentMessagesContext}

User Request: ${userText}
`;

  // Return the prompt configuration.
  return {
    stream: true,
    model: openai('gpt-o3-mini'),
    tools: {},
    prompt: promptString,
  };
};

export default reportsPrompt;
