import shortuuid from '@pkg/uuid';
import { useMessages } from '../context/MessagesContext';
import useCreatePrompt from '../hooks/useCreatePrompt';
import usePrompt from '../hooks/usePrompt';
import intentPrompt from '../prompts/intent';
import mainPrompt from '../prompts/main';

const YAML = require('json-to-pretty-yaml');

const useAIResponse = () => {
  const { addMessage, getMessages, messages, addStream } = useMessages();
  const createPrompt = useCreatePrompt();
  const { prompt } = usePrompt({});

  const sendToAI = async (userText) => {
    let message = {};
    try {
      window.localStorage.getItem('accessToken');

      const intentOptions = await createPrompt({
        basePrompt: intentPrompt,
        userText: userText,
      });

      // addMessage({
      //   text: 'Figuring out intent.',
      //   sender: 'AI',
      //   parameters: { uuid: shortuuid.generate() },
      // });

      const intentResult = await prompt(intentOptions);

      const intent = intentResult.toolResults[0].result;

      console.debug('INTENT22', intent);

      // addMessage({
      //   text: JSON.stringify(intent),
      //   sender: 'AI',
      //   parameters: { uuid: shortuuid.generate() },
      // });

      const promptOptions = await createPrompt({
        intent,
        basePrompt: mainPrompt,
        userText: userText,
      });

      const result = intent?.dataPaths ? await prompt(promptOptions) : {};
      // addStream({ action: null, stream: result });

      if (result?.toolResults?.length) {
        const toolName = result.toolResults[0].toolName;

        if (toolName === 'createMutation') {
          // @todo - do this more elegantly

          const mutation = result.toolResults[0].result.mutation;
          const explanation = result.toolResults[0].result.explanation;

          message = {
            text: explanation,
            type: 'ai',
            sender: 'AI',
            action: 'createMutation',
            parameters: {
              uuid: shortuuid.generate(),
              userQuestion: explanation,
              mutation,
            },
            show: true,
          };
        } else if (toolName === 'generateReport') {
          const question = result.toolResults[0].result.question;
          const keywords = result.toolResults[0].result.keywords;

          message = {
            text: question,
            type: 'ai',
            sender: 'AI',
            action: 'generateReport',
            parameters: {
              uuid: shortuuid.generate(),
              userQuestion: userText,
              question,
              keywords,
            },
          };
        } else if (toolName === 'createFilters') {
          const filterQuestion = result.toolResults[0].result.filter_question;
          message = {
            text: filterQuestion,
            type: 'ai',
            sender: 'AI',
            action: 'createFilters',
            parameters: {
              uuid: shortuuid.generate(),
              userQuestion: userText,
              filterQuestion,
            },
          };
        } else if (toolName === 'navigateTo') {
          const url = result.toolResults[0].result.url;
          const explanation = result.toolResults[0].result.explanation;
          message = {
            text: explanation,
            type: 'ai',
            sender: 'AI',
            action: 'navigateTo',
            parameters: {
              url,
              userQuestion: explanation,
            },
          };
        }
      } else {
        message = {
          text: result.text,
          sender: 'AI',
          parameters: { uuid: shortuuid.generate() },
        };

        const intentOptions = await createPrompt({
          basePrompt: intentPrompt,
          userText: userText,
        });

        const intentResult = await prompt(intentOptions);

        const intent = intentResult.toolResults[0].result;

        // Retrigger the request with streaming enabled
        const promptStreamOptions = await createPrompt({
          basePrompt: mainPrompt,
          intent,
          userText: userText,
          stream: true,
        });

        const streamResult = await prompt({
          ...promptStreamOptions,
          stream: true,
        });
        return { message, stream: streamResult };
      }
    } catch (error) {
      console.error('Error fetching AI response:', error);
      message = {
        text: 'There was an error processing your request.',
        sender: 'AI',
        parameters: { uuid: shortuuid.generate() },
      };
    }

    return { message, stream: null };
  };

  return { sendToAI };
};

export default useAIResponse;
