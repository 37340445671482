import openai from '../client';
import { toolsConfig } from '../tools/config';
import { getPossibleFilters } from './filters/getPossibleFilters';

const YAML = require('json-to-pretty-yaml');

/*
@todo - inject prompts people use often
*/

const filtersPrompt = async ({
  userText,
  activityLibrary,
  tags,
  lastMessages,
  properties,
}) => {
  let filterPrompt = `
      You are an organizational filter creation assistant. You help users create filter configurations to find specific data in their organization.
      You understand how to create complex filter conditions using various comparison operators and entity types.
`;

  filterPrompt += `
    Here is a list of tags and their uuids for the organisation Beamible. When using tags in filters. Use the uuid. Tags can be used in filters to filter activities, groups, and roles

    ${YAML.stringify(
      tags.tags.list.slice(0, 30).map((tag) => ({
        uuid: tag.uuid,
        name: tag.name,
        description: tag.description,
      }))
    )}
    `;

  filterPrompt += `Here is a list of properties and their uuids for the organisation Beamible. When using properties in filters. Use the uuid. Properties can be used in filters to filter activities, groups, and roles.
            
            When setting a property in a filter, use the format 'properties:CGWuJjJtnqf5murGQCRqj' where CGWuJjJtnqf5murGQCRqj is the uuid of the property.

            Text dropdown values:
            ${properties.list
              .slice(30)
              .map((p) => `Name: ${p.name} - Uuid: ${p.uuid}`)
              .join('\n')}

            Property Dropdown Values
           ${properties.list
             .slice(30)
             .map(
               (p) => `
                Name: ${p.name} - Uuid: ${p.uuid}
                - Options: ${
                  p.options
                    ?.slice(30)
                    .map((v) => `Option: ${v.value} - Uuid: ${v.uuid}`)
                    .join('\n') || 'No options'
                }
           `
             )
             .join('\n')}
            `;

  filterPrompt += `

  Here are some more examples of filter configurations

  {
      'filters': {
          'conditions': [
                {
            'entity': {
              'key': 'TAG',
              'label': 'Tag'
            },
            'property': {
              'key': 'ACTIVITY',
              'label': 'Activity'
            },
            'type': 'MULTI_SELECT',
            'propertyId': null,
            'comparator': {
              'key': 'INCLUDE_ANY',
              'label': 'Includes any'
            },
            'value': [
              {
                'id': 'BtMCuemqtmp4KGzaArqr1',
                'label': 'Strategic importance'
              },
              {
                'id': 'BtMCuemqtmp4KGzaArqr2',
                'label': 'Specialist capability'
              }
            ]
          },
          {
            'entity': {
              'key': 'TAG',
              'label': 'Tag'
            },
            'property': {
              'key': 'ACTIVITY',
              'label': 'Activity'
            },
            'type': 'MULTI_SELECT',
            'propertyId': null,
            'comparator': {
              'key': 'INCLUDE_ALL',
              'label': 'Includes all'
            },
            'value': [
              {
                'id': 'BtMCuemqtmp4KGzaArqr1',
                'label': 'Strategic importance'
              },
              {
                'id': 'BtMCuemqtmp4KGzaArqr2',
                'label': 'Specialist capability'
              }
            ]
          },
          {
            'entity': {
              'key': 'TAG',
              'label': 'Tag'
            },
            'property': {
              'key': 'ACTIVITY',
              'label': 'Activity'
            },
            'type': 'MULTI_SELECT',
            'propertyId': null,
            'comparator': {
              'key': 'EXCLUDE_ANY',
              'label': 'Excludes any'
            },
            'value': [
              {
                'id': 'BtMCuemqtmp4KGzaArqr1',
                'label': 'Strategic importance'
              },
              {
                'id': 'BtMCuemqtmp4KGzaArqr2',
                'label': 'Specialist capability'
              }
            ]
          },
          {
                  entity: {
                      key: 'ROLE',
                      label: 'Role',
                  },
                  property: { key: 'title', label: 'Title' },
                  type: 'TEXT',
                  propertyId: null,
                  comparator: { key: 'STARTS_WITH', label: 'Starts with' },
                  value: 'a',
              },
  
              {
                  entity: 'ROLE',
                  property: 'title',
                  type: 'TEXT',
                  propertyId: null,
                  comparator: 'STARTS_WITH',
                  value: 'a',
              }
          ]
        
      }
  }

# Entity Schema and Available Properties

## Activities
- __description: Text description
- hours: Numeric value
- tags: Array of tags
- library_uuid: Activity identifier
- created_at: Timestamp
- updated_at: Timestamp

## Groups
- name: Text identifier
- tags: Array of tags
- fte: Full-time equivalent (numeric)
- budget: Numeric value
- hours: Numeric value
- created_at: Timestamp
- updated_at: Timestamp

## Roles
- title: Text identifier
- tags: Array of tags
- fte: Full-time equivalent (numeric)
- budget: Numeric value
- hours: Numeric value
- created_at: Timestamp
- updated_at: Timestamp

# Filter Construction Guidelines

## Text Comparisons
- Use CONTAINS for partial matches on names, titles, and descriptions
  Example: title CONTAINS 'engineer'

## Numeric Values
- Use GREATER_THAN or LESS_THAN for hours, budget, and FTE
- Always use raw numbers (no string formatting)
- Time conversions:
  - 30 minutes → 0.5
  - 1 hour → 1
  - 1 day → 24
  Example: hours GREATER_THAN 0.5

## Special Properties
- For budget, hours, and FTE on roles/teams:
  Set metricProperty: '__total_metrics'

## Tags
- Use HAS_ITEM_ANY for tag comparisons

## Dates
- Use AFTER or BEFORE for timestamp comparisons

## Activity Type Filtering
- For activity nature/type filters:
  - Use identifier: library_uuid
  - Use operator: ENTITY_IS_ANY
  - Reference activity UUID from activity library

# Available Filter Patterns
The following patterns show valid entity-property-operator combinations.
Format: Entity.Property.ComparisonOperator.FilterType

${getPossibleFilters()
  .map((combo) => combo.dotNotation)
  .join('\n')}

    You return the filters as above based off what user requested.

           Last messages;
          ${lastMessages.map((msg) => `${msg.sender}: ${msg.message}`).join('\n')}

          The last messages are included to help you understand the context of the users request. BUT MAKE SURE TO IGNORE THEM IF THEY ARE NOT RELEVANT. Your response prioritizes the users request if previous mesasages aren't relevant.

      User request: ${userText}
    `;
  const prompt = {
    model: openai('gpt-4o-mini'),
    tools: {
      create_filter: toolsConfig.create_filter.tool,
    },
    prompt: filterPrompt,
  };

  return prompt;
};

export default filtersPrompt;
