import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Close from '@pkg/icons/Close';
import { Str } from '@pkg/utils';
import config from '@/lib/config';
import excludeProps from '@/lib/theme/excludeProps';
import { color } from '@/lib/theme/tokens';
import { LevelColors } from './colors';

const Root = styled('div', {
  shouldForwardProp: excludeProps('expanded'),
})(({ expanded }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: expanded ? 16 : 12,
  lineHeight: 1,
}));

const Skill = styled('div', {
  shouldForwardProp: excludeProps('level'),
})(({ level }) => ({
  backgroundColor: level?.color ?? color.primary.grey[400],
  color: level?.stroke ?? color.core.white,
  borderRadius: 4,
  display: 'inline-flex',
  overflow: 'hidden',
}));

const Label = styled('span')({
  display: 'block',
  fontSize: 10,
  fontWeight: 600,
  lineHeight: '16px',
  padding: '0 4px',
  color: '#fff',
});

const Link = styled('a')({
  display: 'block',
  lineHeight: 1,
  padding: 2,
  '& svg': {
    display: 'block',
    fontSize: 12,
  },
});

const SkillBadge = ({
  canRemove = false,
  skill,
  name,
  onClick = () => {},
  onRemove,
  ...props
}) => {
  const truncated = Str.truncate(name, config.VALUE_LENGTH.DISPLAY.SKILL);
  return (
    <Root {...props} data-testid="skill">
      <Skill level={LevelColors[skill.level]} onClick={() => onClick(skill)}>
        <Label>{truncated}</Label>
        {canRemove && (
          <Link onClick={(event) => onRemove(event, skill)}>
            <Close />
          </Link>
        )}
      </Skill>
    </Root>
  );
};

SkillBadge.propTypes = {
  canRemove: PropTypes.bool,
  name: PropTypes.string,
  skill: PropTypes.object,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

export default SkillBadge;
