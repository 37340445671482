import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

const MAX_RECENT_MESSAGES = 20;

const MessagesContext = createContext(null);

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem('messages');
    const parsedMessages = storedMessages ? JSON.parse(storedMessages) : [];
    // Return only the most recent messages
    return parsedMessages.slice(-MAX_RECENT_MESSAGES);
  });

  useEffect(() => {
    localStorage.setItem('messages', JSON.stringify(messages));
  }, [messages]);

  const getMessages = useCallback(() => {
    const storedMessages = localStorage.getItem('messages');
    const parsedMessages = storedMessages ? JSON.parse(storedMessages) : [];
    // Return only the most recent messages
    return parsedMessages.slice(-MAX_RECENT_MESSAGES);
  }, []);

  const addMessage = useCallback(
    ({ text, sender = 'User', action, parameters }) => {
      const actions = [];
      if (action) {
        actions.push({ type: action, applied: false, parameters });
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        { message: text, sender, actions },
      ]);
    },
    []
  );

  const addStream = useCallback(({ action, stream, sender = 'Bot' }) => {
    console.debug({ stream, action, sender });
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: '', sender, actions: action ? [] : [] },
    ]);

    let fullText = '';
    const updateStream = async () => {
      try {
        for await (const textPart of stream.textStream) {
          fullText += textPart;
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            const lastIndex = newMessages.length - 1;
            newMessages[lastIndex] = {
              ...newMessages[lastIndex],
              message: fullText,
            };
            return newMessages;
          });
        }
      } catch (error) {
        console.error('Error in stream processing:', error);
      }
    };

    updateStream();
  }, []);

  const updateMessageAction = useCallback(
    (index, actionType, appliedStatus) => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const message = updatedMessages[index];
        if (message) {
          const actionIndex = message.actions.findIndex(
            (action) => action.type === actionType
          );
          if (actionIndex > -1) {
            if (message.actions[actionIndex].applied === appliedStatus) {
              return prevMessages; // No change needed
            }
            message.actions[actionIndex].applied = appliedStatus;
          } else {
            message.actions.push({ type: actionType, applied: appliedStatus });
          }
        }
        return updatedMessages;
      });
    },
    []
  );

  const value = useMemo(
    () => ({
      messages,
      getMessages,
      addMessage,
      addStream,
      updateMessageAction,
    }),
    [messages, getMessages, addMessage, addStream, updateMessageAction]
  );

  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }
  return context;
};
