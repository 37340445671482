import { deriveProps as deriveSnapshot } from '../snapshots';

/**
 * @param {Object} library
 * @param {Object} revision
 * @return {Object}
 */
export default function deriveProps({
  library,
  revision,
  includeHierarchy = true,
}) {
  if (!revision?.snapshot) {
    return revision;
  }

  const snapshot = deriveSnapshot({
    library,
    snapshot: revision.snapshot,
    includeHierarchy,
  });

  return {
    ...revision,
    snapshot,
    __metrics: snapshot.__metrics,
    __percentage: snapshot.__percentage,
    __visibility: snapshot.__visibility,

    /** @deprecated */
    __total_metrics: snapshot.__total_metrics,
    __visible_metrics: snapshot.__visible_metrics,
  };
}
