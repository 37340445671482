import getSummarised from '@pkg/entities/tags/get/summarised';
import { Num } from '@pkg/utils';
import { DesignEntity, Visibility } from '@/lib/enums';
import SnapshotMetricGroup from '../enums/SnapshotMetricGroup';
import defaultEntityMetrics from '../utils/defaultEntityMetrics';
import roundFloatMetrics from './shared/roundFloatMetrics';

export default { derive, apply };

/**
 * @param {Object} library
 * @param {Object} snapshot
 * @param {Object} metrics
 */
function derive({ library, snapshot, metrics }) {
  snapshot.__keys.roles.forEach((id) => {
    if (!metrics.has(id)) {
      metrics.set(id, defaultEntityMetrics());
    }

    // normalize props
    const role = snapshot.entities.roles[id];
    role.properties = role.properties ?? [];
    role.skills = role.skills ?? [];
    role.tags = role.tags ?? [];

    role.budget = Number.parseInt(role.budget);
    role.budget = Number.isFinite(role.budget) ? role.budget : null;
    const hasBudget = Number.isFinite(role.budget);

    role.fte = Num.roundFloat(role.fte);
    role.fte = Number.isFinite(role.fte) ? role.fte : null;
    const hasFte = Number.isFinite(role.fte);

    role.__person = snapshot.entities.people[role.user_uuid] ?? null;

    const properties = role.properties ?? [];
    role.__properties = new Map(
      properties.map(({ key, value }) => [key, value])
    );

    role.__skill_map = new Map();
    role.skills.forEach(({ uuid, level }) => role.__skill_map.set(uuid, level));

    role.__tags_set = new Set(role.tags);
    role.__tags_summarised = getSummarised(library.tags.tags, role.tags);

    role.__type = DesignEntity.ROLE;
    role.__visibility = role.__visibility ?? Visibility.FULL;
    const isVisible = role.__visibility === Visibility.FULL;

    // setup metrics
    const roleMetrics = metrics.get(id);
    const totalActivities = roleMetrics.self.total.activities;
    const visibleActivities = roleMetrics.self.visible.activities;

    const totalHours = roleMetrics.self.total.hours;
    const visibleHours = roleMetrics.self.visible.hours;
    const factor = Num.percent(visibleHours, totalHours) / 100;

    const totalBudget = hasBudget ? role.budget : 0;
    const visibleBudget = hasBudget ? role.budget * factor : 0;

    const totalFte = hasFte ? role.fte : 0;
    const visibleFte = hasFte ? role.fte * factor : 0;

    roleMetrics.self.total.roles = 1;
    roleMetrics.self.visible.roles = isVisible ? 1 : 0;
    roleMetrics.self.total.budget = hasBudget ? totalBudget : null;
    roleMetrics.self.visible.budget = hasBudget ? visibleBudget : null;
    roleMetrics.self.total.fte = hasFte ? totalFte : null;
    roleMetrics.self.visible.fte = hasFte ? visibleFte : null;

    // snapshot metrics
    const root = metrics.get('*');
    root.total.roles += 1;
    root.total.budget += totalBudget;
    root.total.fte += totalFte;

    if (isVisible) {
      root.visible.roles += 1;
      root.visible.budget += visibleBudget;
      root.visible.fte += visibleFte;
    }

    // Exit if we don't have hierarchy properties.
    if (!role.__path) {
      return;
    }

    role.__depth = role.__above ? role.__above[DesignEntity.ROLE].size + 1 : 1;

    // iterate path and metrics
    const rolePath = Array.from(role.__path.entries());
    rolePath.forEach(([entityId, { type }]) => {
      if (!metrics.has(entityId)) {
        metrics.set(entityId, defaultEntityMetrics());
      }

      const entityMetrics = metrics.get(entityId);
      const entityPlural = DesignEntity.toPlural(type);
      const entity = snapshot.entities[entityPlural][entityId];

      const isManaged = type === DesignEntity.GROUP || entity.is_manager;
      const isGroup = entityId === role.group_uuid;
      const isSpan = entity.__span[DesignEntity.ROLE].has(role.uuid);

      // handle managed
      if (isManaged) {
        const managed = entityMetrics.managed;
        managed.total.roles += 1;
        managed.total.activities += totalActivities;
        managed.total.hours += totalHours;
        managed.total.budget += totalBudget;
        managed.total.fte += totalFte;

        if (isVisible) {
          managed.visible.roles += 1;
          managed.visible.activities += visibleActivities;
          managed.visible.hours += visibleHours;
          managed.visible.budget += visibleBudget;
          managed.visible.fte += visibleFte;
        }
      }

      // handle group self
      if (isGroup) {
        const self = entityMetrics.self;
        self.total.roles += 1;
        self.total.activities += totalActivities;
        self.total.hours += totalHours;
        self.total.budget += totalBudget;
        self.total.fte += totalFte;

        if (isVisible) {
          self.visible.roles += 1;
          self.visible.activities += visibleActivities;
          self.visible.hours += visibleHours;
          self.visible.budget += visibleBudget;
          self.visible.fte += visibleFte;
        }
      }

      // handle span
      if (isSpan) {
        const span = entityMetrics.span;
        span.total.roles += 1;
        span.total.activities += totalActivities;
        span.total.hours += totalHours;
        span.total.budget += totalBudget;
        span.total.fte += totalFte;

        if (isVisible) {
          span.visible.roles += 1;
          span.visible.activities += visibleActivities;
          span.visible.hours += visibleHours;
          span.visible.budget += visibleBudget;
          span.visible.fte += visibleFte;
        }
      }
    });
  });
}

/**
 * Apply the metrics once everything is derived
 * @param {Object} keyed
 * @param {Map} metrics
 */
function apply(keyed, metrics) {
  const metricKeys = Object.keys(SnapshotMetricGroup);
  keyed.__keys.roles.forEach((id) => {
    const role = keyed.entities.roles[id];
    role.__metrics = roundFloatMetrics(metrics.get(id));
    role.__percentage = null;

    // calculate percentage
    if (Number.isFinite(role.__metrics.self.total.hours)) {
      const totalHours = role.__metrics.self.total.hours;
      const visibleHours = role.__metrics.self.visible.hours;
      role.__percentage = Num.percent(visibleHours, totalHours);
    }

    // remove non-manager managed metrics
    if (!role.is_manager) {
      role.__layers = null;
      metricKeys.forEach((key) => {
        role.__metrics.managed.total[key] = null;
        role.__metrics.managed.visible[key] = null;
        role.__metrics.span.total[key] = null;
        role.__metrics.span.visible[key] = null;
      });
    }

    // determine layers for managers
    if (role.is_manager && role.__managed) {
      role.__layers = 1;
      role.__managed[DesignEntity.MANAGER].forEach((managerId) => {
        const manager = keyed.entities.roles[managerId];
        const layers = manager.__layer - role.__layer + 1;
        role.__layers = Math.max(role.__layers, layers);
      });
    }

    /** @deprecated */
    role.__total_metrics = role.__metrics.self.total;
    role.__visible_metrics = role.__metrics.self.visible;
  });
}
