import { useLibrary } from '@pkg/entities/library';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { useMessages } from '../context/MessagesContext';

function reduceIntentSnapshot(snapshot, dataPaths) {
  const result = {
    entities: {
      roles: [],
      activities: [],
      groups: [],
      people: [],
    },
  };

  dataPaths.forEach((path) => {
    const pathParts = path.replace(/^snapshot\./, '').split('.');
    let currentData = snapshot;

    // Check if we're dealing with an entity array
    if (
      pathParts[0] === 'entities' &&
      ['roles', 'activities', 'groups', 'people'].includes(pathParts[1])
    ) {
      const entityType = pathParts[1];
      currentData = currentData[pathParts[0]][entityType];

      // Process each entity
      currentData.forEach((entity, index) => {
        // Initialize entity object if not exists
        if (!result.entities[entityType][index]) {
          result.entities[entityType][index] = {};
        }

        // Handle different path depths
        if (pathParts.length === 3) {
          // Simple property like 'title'
          result.entities[entityType][index][pathParts[2]] =
            entity[pathParts[2]];
        } else if (pathParts.length > 3) {
          // Nested property like '__total_metrics.hours'
          const nestedProp = pathParts[2];
          const finalProp = pathParts[3];

          // Ensure nested object exists
          if (!result.entities[entityType][index][nestedProp]) {
            result.entities[entityType][index][nestedProp] = {};
          }

          // Set the final property value
          result.entities[entityType][index][nestedProp][finalProp] = entity[
            nestedProp
          ]
            ? entity[nestedProp][finalProp]
            : null;
        }
      });
    }
  });

  return result;
}

const useCreatePrompt = () => {
  const snapshot = useDesignStore((state) => state.snapshot);
  const level = useDesignStore((state) => state.level);

  const {
    tags,
    activities: activityLibrary,
    flowTemplates,
    properties,
    skills,
  } = useLibrary();
  const { messages } = useMessages();
  const lastMessages = messages.slice(-8);

  const createPrompt = async ({
    basePrompt,
    intent,
    userText,
    stream = false,
  }) => {
    let intentSnapshot = null;

    if (intent) {
      const dataPaths = intent.dataPaths;
      if (dataPaths) {
        intentSnapshot = reduceIntentSnapshot(snapshot, dataPaths);
      }
    }

    const {
      roles = [],
      activities = [],
      groups = [],
      people = [],
    } = snapshot.entities || {};
    return await basePrompt({
      stream,
      userText,
      snapshot,
      tags,
      intentSnapshot,
      activityLibrary,
      flowTemplates,
      lastMessages,
      properties,
      roles,
      skills,
      groups,
      people,
      level,
    });
  };

  return createPrompt;
};

export default useCreatePrompt;
