import { Num, Obj } from '@pkg/utils';
import { DesignEntity, Visibility } from '@/lib/enums';
import make from '../make';
import defaultSnapshotMetrics from '../utils/defaultSnapshotMetrics';
import getFlattened from '../utils/getFlattened';
import getHierarchy from '../utils/getHierarchy';
import getKeyed from '../utils/getKeyed';
import activities from './activities';
import groups from './groups';
import people from './people';
import roles from './roles';
import roundFloatMetrics from './shared/roundFloatMetrics';

/**
 * @param {Object} snapshot
 * @param {Object} library
 * @return {Object}
 */
export default function deriveSnapshot({
  library,
  snapshot,
  includeMake = true,
  includeKeyed = true,
  includeHierarchy = true,
  includeMetrics = true,
  flatten = true,
}) {
  if (Obj.isEmpty(snapshot)) {
    return snapshot;
  }

  // setup
  console.groupCollapsed('Snapshots.deriveProps');
  console.time('Snapshots.deriveProps');

  let derived = snapshot;

  if (includeMake) {
    console.time('Snapshots.deriveProps.make');
    derived = make(snapshot);
    console.timeEnd('Snapshots.deriveProps.make');
  }

  if (includeKeyed) {
    console.time('Snapshots.deriveProps.utils.getKeyed');
    derived = getKeyed(derived, false);
    console.timeEnd('Snapshots.deriveProps.utils.getKeyed');
  }

  if (includeHierarchy) {
    console.time('Snapshots.deriveProps.utils.getHierarchy');
    derived = getHierarchy({ snapshot: derived });
    console.timeEnd('Snapshots.deriveProps.utils.getHierarchy');
  }

  if (includeMetrics && library) {
    const metrics = new Map();
    metrics.set('*', defaultSnapshotMetrics());

    // derive entities
    console.time('Snapshots.deriveProps.entities.derive');
    activities.derive({ library, snapshot: derived, metrics });
    roles.derive({ library, snapshot: derived, metrics });
    groups.derive({ library, snapshot: derived, metrics });
    people.derive({ snapshot: derived, metrics });
    console.timeEnd('Snapshots.deriveProps.entities.derive');

    // apply
    console.time('Snapshots.deriveProps.entities.apply');
    activities.apply(derived, metrics);
    roles.apply(derived, metrics);
    groups.apply(derived, metrics);
    people.apply(derived, metrics);
    console.timeEnd('Snapshots.deriveProps.entities.apply');

    // finalize
    console.time('Snapshots.deriveProps.totals');
    const root = roundFloatMetrics(metrics.get('*'), false);
    derived.__metrics = root;
    derived.__percentage = null;
    derived.__type = DesignEntity.ORGANISATION;
    derived.__visibility = derived.__visibility ?? Visibility.FULL;

    if (Number.isFinite(root.total.hours)) {
      derived.__percentage = Num.percent(root.visible.hours, root.total.hours);
    }

    /** @deprecated */
    derived.__total_metrics = root.total;
    derived.__visible_metrics = root.visible;
    console.timeEnd('Snapshots.deriveProps.totals');
  }

  console.timeEnd('Snapshots.deriveProps');
  console.groupEnd('Snapshots.deriveProps');
  return flatten ? getFlattened(derived) : Object.freeze(derived);
}
