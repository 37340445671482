import { DesignEntity } from '@/lib/enums';
import defaultEntityMetrics from '../utils/defaultEntityMetrics';

export default { derive, apply };

/**
 * @param {Object} library
 * @param {Object} snapshot
 * @param {Object} metrics
 */
function derive({ snapshot, metrics }) {
  snapshot.__keys.people.forEach((id) => {
    if (!metrics.has(id)) {
      metrics.set(id, defaultEntityMetrics());
    }

    const person = snapshot.entities.people[id];
    person.__name = `${person.first_name ?? ''} ${person.last_name ?? ''}`;
    person.__name = person.__name.trim();
    person.__pathname = `/u/${id}`;
    person.__type = DesignEntity.PERSON;
  });
}

/**
 * Apply the metrics once everything is derived
 * @param {Object} keyed
 * @param {Object} metrics
 */
function apply(keyed, metrics) {
  keyed.__keys.people.forEach((id) => {
    /** @todo apply metrics */
    const person = keyed.entities.people[id];
    person.__metrics = metrics.get(person.uuid);

    /** @deprecated */
    person.__total_metrics = person.__metrics.self.total;
    person.__visible_metrics = person.__metrics.self.visible;
  });
}
