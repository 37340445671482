import { useMemo } from 'react';
import serialize from '@pkg/utils/strings/serialize';
import deriveTags from './deriveTags';

/**
 * @param {Object} snapshot
 * @return {Object}
 */
export default function useDerivedTags(snapshot) {
  return useMemo(() => {
    const scenario = deriveTags(snapshot);

    if (!scenario) {
      return null;
    }

    return Object.freeze(scenario);
  }, [serialize(snapshot)]);
}
