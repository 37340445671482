import getSummarised from '@pkg/entities/tags/get/summarised';
import { Colors, Num, Obj } from '@pkg/utils';
import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import defaultEntityMetrics from '../utils/defaultEntityMetrics';

export default { derive, apply };

/**
 * @param {Object} library
 * @param {Object} snapshot
 * @param {Map} metrics
 */
function derive({ library, snapshot, metrics }) {
  if (Obj.isEmpty(snapshot.entities.activities)) {
    return;
  }

  // normalize and iterate path
  snapshot.__keys.activities.forEach((id) => {
    const activity = snapshot.entities.activities[id];

    const ownerPlural = DesignEntity.toPlural(activity.owner_type);
    const owner = snapshot.entities[ownerPlural][activity.owner_uuid];
    const isAllocated = activity.owner_type === DesignEntity.ROLE;

    activity.order = Number(activity.order || 0);
    activity.properties = activity.properties ?? [];
    activity.tags = activity.tags ?? [];

    activity.__color = Colors.seededOld(activity.uuid);
    activity.__description =
      library.activities.mapped.get(activity.library_uuid)?.description ??
      activity.__description ??
      'DELETED';

    activity.__group = owner?.[isAllocated ? 'group_uuid' : 'uuid'] ?? null;
    activity.__budget = null;
    activity.__percentage = null;

    const properties = activity.properties ?? [];
    activity.__properties = new Map(
      properties.map(({ key, value }) => [key, value])
    );

    const tagsSummarised = getSummarised(library.tags.tags, activity.tags);
    activity.__tags_set = new Set(activity.tags);
    activity.__tags_summarised = tagsSummarised;

    activity.__type = DesignEntity.ACTIVITY;
    activity.__visibility = activity.__visibility ?? config.VISIBILITY.FULL;
    const isVisible = activity.__visibility === config.VISIBILITY.FULL;

    // normalize hours
    activity.hours = Num.roundFloat(activity.hours);
    activity.hours = Number.isFinite(activity.hours) ? activity.hours : null;
    const hasHours = Number.isFinite(activity.hours);

    const totalHours = hasHours ? activity.hours : 0;
    const visibleHours = hasHours && isVisible ? activity.hours : 0;

    // skip disabled
    if (activity.disabled_at) {
      return;
    }

    // snapshot metrics
    const root = metrics.get('*');
    root.total.activities += 1;
    root.total.hours += totalHours;

    if (isVisible) {
      root.visible.activities += 1;
      root.visible.hours += visibleHours;
    }

    // owner metrics
    if (!metrics.has(activity.owner_uuid)) {
      metrics.set(activity.owner_uuid, defaultEntityMetrics());
    }

    const ownerMetrics = metrics.get(activity.owner_uuid);
    ownerMetrics.self.total.activities += 1;
    ownerMetrics.self.total.hours += totalHours;

    if (isVisible) {
      ownerMetrics.self.visible.activities += 1;
      ownerMetrics.self.visible.hours += visibleHours;
    }
  });
}

/**
 * Apply the metrics once everything is derived
 * @param {Object} keyed
 * @param {Map} metrics
 */
function apply(keyed, metrics) {
  keyed.__keys.activities.forEach((id) => {
    const activity = keyed.entities.activities[id];
    activity.__percentage = null;

    if (Number.isFinite(activity.hours)) {
      const ownerMetrics = metrics.get(activity.owner_uuid);
      const totalHours = ownerMetrics.self.total.hours;
      activity.__percentage = Num.percent(activity.hours, totalHours);

      if (Number.isFinite(ownerMetrics.self.total.budget)) {
        activity.__budget =
          (ownerMetrics.self.total.budget / 100) * activity.__percentage;
      }
    }
  });
}
