import { EntityType } from '@/shared/enums';
import { PropertyType } from '@/lib/enums';
import aggregateEntity from './aggregateEntity';
import getEntityProps from './getEntityProps';

/**
 * Creates a nested map of properties and the entities provided in the order
 * array.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function mapPropertyEntityItems({
  activity,
  group,
  entityMetrics,
  entities,
  map,
  order,
  orderIndex,
  person,
  remainingSet,
  role,
  properties,
  propertyMap,
}) {
  const remainingOrder = order.slice(orderIndex);

  properties.forEach(({ key, value }) => {
    let updatedMap = map;

    // Get the property and property value properties from the property map.
    const property = propertyMap?.get(key);
    const propertyValue =
      property.type === PropertyType.DROPDOWN
        ? property.map.get(value)
        : { uuid: btoa(value), name: value };

    // Exit if the property or it's value didn't exist in the property map.
    if (!property || !propertyValue) {
      return;
    }

    // Loop through all entities in the order array and update the map
    // accordingly.
    remainingOrder.forEach((entityType, index) => {
      const nextEntity = remainingOrder[index + 1];

      // Standardise the property and property value props to simplify retriving
      // the entity props.
      const propertyProps =
        entityType === EntityType.PROPERTY
          ? {
              uuid: property.uuid,
              name: property.name,
              scope: property.scope,
            }
          : {
              ...propertyValue,
              property: property.name,
              scope: property.scope,
            };

      // Collect the entity props to be stored in the map based on the entity
      // type we're currently working with.
      const props = getEntityProps({
        activity,
        entityType,
        group,
        role,
        person,
        property: propertyProps,
      });

      // Add the values for this entity type to the map and point the map to the
      // next entity to continue the recursive updates.
      updatedMap = aggregateEntity({
        ...props,
        entities,
        existingMetrics: entityMetrics,
        map: updatedMap,
        nextEntity,
        remainingSet,
        type: entityType,
      });
    });
  });

  return map;
}
