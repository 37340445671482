import { tool } from 'ai';
import { z } from 'zod';

const createMutation = tool({
  description:
    'Create a mutation to add a new position or role in the organization',
  parameters: z.object({
    explanation: z
      .string()
      .describe(
        'Describe the position being added to the organization. Example: "Add a new Marketing Director position reporting to the CMO"'
      ),
  }),
  execute: async ({ explanation }) => ({
    explanation,
  }),
});

export default createMutation;
