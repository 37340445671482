import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { ToggleButton } from '@/atoms/buttons';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { Tooltip } from '@/molecules/modals';
import { DesignSelector } from '@/molecules/navigation';
import ModeSelector from '@/molecules/navigation/ModeSelector/ModeSelector';
import Filters from '@/organisms/filters';
import { AddScenario } from '@/organisms/forms';
import { EventName, EventProperty } from '@/shared/enums';
import { FilterProvider } from '@/shared/providers';
import { useViewModeContext, useCanSwitchViewModes } from '@/shared/providers';
import AttachMoney from '@mui/icons-material/AttachMoney';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EditOffIcon from '@mui/icons-material/EditOff';
import MoneyOff from '@mui/icons-material/MoneyOff';
import Stack from '@mui/material/Stack';
import { Analytics } from '@pkg/analytics';
import * as Auth from '@pkg/auth';
import { Feature } from '@/lib/enums';
import { core } from '@/lib/theme/tokens/palettes';
import BulkEditModal from '@/components/BulkEdit/BulkEditModal';
import Copilot from '@/components/Copilot/Copilot';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import AuthenticatedToolbar from '@/components/DesignContainer/parts/Layout/Toolbars/Authenticated';

const SecondaryNavigationBar = ({ viewMode }) => {
  const isHistorical = useDesignStore((state) => state.isHistorical);
  const {
    selectViewMode,
    canAccessBudget,
    showBudget,
    updateBudgetVisibility,
    view,
  } = useViewModeContext();
  const canSwitchViewModes = useCanSwitchViewModes();
  const level = useDesignStore((state) => state.level);
  const access = useDesignStore((state) => state.access);
  const hasPlaygroundFeature = access.features[Feature.PLAYGROUND];
  const isPlaygroundable = hasPlaygroundFeature;

  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [copilotOpen, setCopilotOpen] = useState(false);
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);

  const handleWizard = () => {
    setBulkModalOpen(!bulkModalOpen);
  };

  const handleCopilot = () => {
    setCopilotOpen(!copilotOpen);
  };

  const handleSelectViewMode = (modeId) => {
    selectViewMode(modeId);
    Analytics.track(EventName.SWITCHED_VIEW_MODE, {
      [EventProperty.ENTRY]: 'Secondary navigation',
      [EventProperty.VIEW_MODE]: modeId,
    });
  };

  return (
    <FilterProvider>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px={2}
        sx={{
          backgroundColor: core.white,
          boxShadow: `0 1px 0 0 #C8D2E4, 0 2px 0 0 #E0E8F4`,
          justifyContent: 'space-between',
        }}
      >
        <Stack alignItems="center" direction="row" py={1} spacing={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Paragraph size={Size.SMALL}>Design:</Paragraph>
            <DesignSelector viewMode={viewMode} />
          </Stack>
          {canSwitchViewModes && (
            <ModeSelector initial={view} onClick={selectViewMode} />
          )}
          <Filters />
          {isAuthenticated && (
            <>
              <div>
                <Button
                  label="Bulk Edit"
                  color="light-blue"
                  onClick={handleWizard}
                  startIcon={<EditOffIcon />}
                />
                {bulkModalOpen && (
                  <BulkEditModal
                    opened={bulkModalOpen}
                    onClose={() => {
                      setBulkModalOpen(false);
                    }}
                  />
                )}
              </div>
            </>
          )}

          {isPlaygroundable && (
            <>
              <div>
                <Button
                  label="Ai Playground"
                  color="light-blue"
                  onClick={handleCopilot}
                  startIcon={<AutoAwesomeIcon />}
                />
                {copilotOpen && (
                  <Copilot
                    opened={copilotOpen}
                    onClose={() => {
                      setCopilotOpen(false);
                    }}
                  />
                )}
              </div>
            </>
          )}
          {canAccessBudget && (
            <>
              <Divider orientation="vertical" />
              <Tooltip
                title={showBudget ? 'Hide budgets' : 'Show budgets'}
                position="bottom"
              >
                <div>
                  <ToggleButton
                    color="secondary"
                    initialState={showBudget}
                    onChange={updateBudgetVisibility}
                    onIcon={<AttachMoney />}
                    offIcon={<MoneyOff />}
                    size={Size.SMALL}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </Stack>
        {isHistorical && <AuthenticatedToolbar />}
        {!isHistorical && <AddScenario />}
      </Stack>
    </FilterProvider>
  );
};

export default SecondaryNavigationBar;
