import { tool } from 'ai';
import { z } from 'zod';
import openai from '../client';
import lastMessagesSlice from './slices/lastMessagesSlice';
import systemSlice from './slices/systemSlice';

function buildPaths(obj, parentPath = '', ignorePaths = []) {
  let paths = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const fullPath = parentPath ? `${parentPath}.${key}` : key;

      // Check if the current path matches any ignorePaths
      if (ignorePaths.some((ignorePath) => fullPath.startsWith(ignorePath))) {
        continue; // Skip this path if it's in the ignorePaths
      }

      paths.push(fullPath);

      // Check if it's an array, and process only the fields of the first item
      if (
        Array.isArray(obj[key]) &&
        obj[key].length > 0 &&
        typeof obj[key][0] === 'object'
      ) {
        // Only recurse into the fields of the first item in the array
        paths = paths.concat(buildPaths(obj[key][0], fullPath, ignorePaths));
      }
      // If it's a nested object (but not an array), recurse through its properties
      else if (typeof obj[key] === 'object' && obj[key] !== null) {
        paths = paths.concat(buildPaths(obj[key], fullPath, ignorePaths));
      }
    }
  }

  return paths;
}

const intentPrompt = async ({ userText, snapshot, lastMessages }) => {
  const ignorePaths = [
    'snapshot.entities.groups.__tags',
    'snapshot.entities.roles.__tags',
    'snapshot.entities.roles.__person',
    'snapshot.entities.activities.__color',
    'snapshot.__tags',
  ];

  const paths = buildPaths(snapshot, 'snapshot', ignorePaths);

  console.debug('intent', { paths });
  const prompt = `
    ${systemSlice()}


    Your job is to figure out the users intent and what data they may require to answer their question.

    You MUST use the get_intent_from_user_request tool to analyze the user's request.
    NEVER respond with a text message.

    Your task is to:
    1. Determine if data is needed to answer the question
    2. If data is needed, identify the exact data paths required
    3. Return null for dataPaths if no data access is needed
    4. Always provide a clear intent description

    Here is the data structure of people, groups, roles and activities;

    type People {
      uuid: UUID!
      first_name: String!
      last_name: String!
      name: String!
      email: String!
      avatar: String
      salary: Int @canAccess(ability: "viewSalary")
      fte: Float!
      skills: [Skill!]
    }

    type Group {
      uuid: UUID!
      lead_uuid: UUID      # Links to a person who leads this group
      group_uuid: UUID     # Links to a parent group if this is a subgroup
      name: String!
      objective: String
      tags: [UUID!]
      properties: [KeyValuePair!]
      order: Int
      created_at: Float!
      updated_at: Float!
    }

    type Role {
      uuid: UUID!
      external_id: String
      parent_uuid: UUID    # Links to a parent role if this is a subrole
      group_uuid: UUID     # Links to the group this role belongs to
      user_uuid: UUID      # Links to the person assigned to this role
      is_manager: Boolean
      title: String!
      order: Int!
      fte: Float
      budget: Int
      skills: [Skill!]
      tags: [UUID!]
      properties: [KeyValuePair!]
      created_at: Float!
      updated_at: Float!
    }

    type Activity {
      uuid: UUID!
      library_uuid: UUID!
      owner_uuid: UUID!    # Links to the role or group that owns this activity
      owner_type: ActivityOwner!
      source_uuid: String
      source_type: EntitySource
      hours: Float!
      order: Int!
      tags: [UUID!]
      properties: [KeyValuePair!]
      __description: String!  # Description of the activity - ALWAYS needed for context
      created_at: Float!
      disabled_at: Float!
      updated_at: Float!
    }

    Entity Relationships and Required Fields:
    When working with multiple entities, you MUST include the linking IDs in your dataPaths to maintain proper relationships:

    1. Activities → Roles:
       - Always include: activities.owner_uuid AND roles.uuid
       - Example: When modifying activities for a role, you need both to link them

    2. Roles → Groups:
       - Always include: roles.group_uuid AND groups.uuid
       - Example: When working with roles in a group, you need both to establish the hierarchy

    3. Groups → People:
       - Always include: groups.lead_uuid AND people.uuid
       - Example: When dealing with group leadership, you need both to identify the leader

    4. Roles → People:
       - Always include: roles.user_uuid AND people.uuid
       - Example: When assigning people to roles, you need both IDs

    5. Subgroups → Parent Groups:
       - Always include: groups.group_uuid AND parent group's uuid
       - Example: When working with group hierarchies, you need both to establish the relationship

    6. Subroles → Parent Roles:
       - Always include: roles.parent_uuid AND parent role's uuid
       - Example: When dealing with role hierarchies, you need both to maintain the structure

    Common Operations and Required DataPaths:
    1. Modifying Activity Tags:
       MUST include:
       - snapshot.entities.activities.tags (to modify tags)
       - snapshot.entities.activities.uuid (to identify activities)
       - snapshot.entities.activities.owner_uuid (to link to owner)
       - snapshot.entities.activities.__description (to understand activity context)
       - snapshot.entities.roles.uuid (to match with owner_uuid)
       - snapshot.entities.roles.title (to find specific roles)

    2. Working with Role Activities:
       MUST include:
       - snapshot.entities.roles.uuid
       - snapshot.entities.roles.title
       - snapshot.entities.activities.owner_uuid
       - snapshot.entities.activities.uuid
       - snapshot.entities.activities.__description

    3. Updating Properties:
       MUST include:
       - [entity].uuid
       - [entity].properties
       - [entity].title or name (if searching by name)
       - snapshot.entities.activities.__description (if working with activities)

    4. Working with Tags:
       MUST include:
       - [entity].tags
       - [entity].uuid
       - Any linking IDs (owner_uuid, parent_uuid, etc.)
       - snapshot.entities.activities.__description (if working with activities)
       Note: Tag definitions are already provided elsewhere in the prompt

    Important Rules:
    1. ALWAYS include both sides of a relationship in dataPaths when working across entities
    2. When modifying tags or properties, include the respective entity's uuid
    3. For any hierarchical operations (subgroups, subroles), include both parent and child IDs
    4. For ownership relationships (activities → roles/groups), always include owner_uuid and the owner entity's uuid
    5. When searching by name/title, ALWAYS include the entity's uuid to maintain proper relationships
    6. When working with activities, ALWAYS include their __description for context
    7. For any tag operations, include the entity's tags array and uuid (tag definitions are provided separately)

    Example Scenarios:
    1. "Tag activities in a specific role":
       Required paths:
       - snapshot.entities.roles.title (find the role)
       - snapshot.entities.roles.uuid (link to activities)
       - snapshot.entities.activities.owner_uuid (match with role uuid)
       - snapshot.entities.activities.uuid (identify activities)
       - snapshot.entities.activities.__description (understand activity context)
       - snapshot.entities.activities.tags (modify tags)

    2. "Move activities between roles":
       Required paths:
       - snapshot.entities.roles.title (find roles)
       - snapshot.entities.roles.uuid (both source and target)
       - snapshot.entities.activities.owner_uuid (to update)
       - snapshot.entities.activities.uuid (identify activities)
       - snapshot.entities.activities.__description (understand activity context)

    Here are all the paths to the data available.
    
  - "snapshot.name"
  - "snapshot.objective"
  - "snapshot.created_at"
  - "snapshot.entities"
  - "snapshot.entities.groups"
  - "snapshot.entities.groups.uuid"
  - "snapshot.entities.groups.group_uuid"
  - "snapshot.entities.groups.lead_uuid"
  - "snapshot.entities.groups.name"
  - "snapshot.entities.groups.objective"
  - "snapshot.entities.groups.properties"
  - "snapshot.entities.groups.tags"
  - "snapshot.entities.groups.order"
  - "snapshot.entities.groups.created_at"
  - "snapshot.entities.groups.updated_at"
  - "snapshot.entities.groups.__type"
  - "snapshot.entities.groups.__visibility"
  - "snapshot.entities.groups.__metrics"
  - "snapshot.entities.groups.__total_metrics"
  - "snapshot.entities.groups.__total_metrics.groups"
  - "snapshot.entities.groups.__total_metrics.roles"
  - "snapshot.entities.groups.__total_metrics.activities"
  - "snapshot.entities.groups.__total_metrics.hours"
  - "snapshot.entities.groups.__total_metrics.fte"
  - "snapshot.entities.groups.__total_metrics.budget"
  - "snapshot.entities.groups.__visible_metrics"
  - "snapshot.entities.groups.__visible_metrics.groups"
  - "snapshot.entities.groups.__visible_metrics.roles"
  - "snapshot.entities.groups.__visible_metrics.activities"
  - "snapshot.entities.groups.__visible_metrics.hours"
  - "snapshot.entities.groups.__visible_metrics.fte"
  - "snapshot.entities.groups.__visible_metrics.budget"
  - "snapshot.entities.groups.__completeness"
  - "snapshot.entities.roles"
  - "snapshot.entities.roles.uuid"
  - "snapshot.entities.roles.group_uuid"
  - "snapshot.entities.roles.parent_uuid"
  - "snapshot.entities.roles.user_uuid"
  - "snapshot.entities.roles.is_manager"
  - "snapshot.entities.roles.title"
  - "snapshot.entities.roles.fte"
  - "snapshot.entities.roles.budget"
  - "snapshot.entities.roles.properties"
  - "snapshot.entities.roles.skills"
  - "snapshot.entities.roles.skills.uuid"
  - "snapshot.entities.roles.skills.level"
  - "snapshot.entities.roles.tags"
  - "snapshot.entities.roles.order"
  - "snapshot.entities.roles.created_at"
  - "snapshot.entities.roles.updated_at"
  - "snapshot.entities.roles.__percentage"
  - "snapshot.entities.roles.__layers"
  - "snapshot.entities.roles.__total_metrics"
  - "snapshot.entities.roles.__total_metrics.groups"
  - "snapshot.entities.roles.__total_metrics.roles"
  - "snapshot.entities.roles.__total_metrics.activities"
  - "snapshot.entities.roles.__total_metrics.hours"
  - "snapshot.entities.roles.__total_metrics.fte"
  - "snapshot.entities.roles.__total_metrics.budget"
  - "snapshot.entities.roles.__visible_metrics"
  - "snapshot.entities.roles.__visible_metrics.groups"
  - "snapshot.entities.roles.__visible_metrics.roles"
  - "snapshot.entities.roles.__visible_metrics.activities"
  - "snapshot.entities.roles.__visible_metrics.hours"
  - "snapshot.entities.roles.__visible_metrics.fte"
  - "snapshot.entities.roles.__visible_metrics.budget"
  - "snapshot.entities.roles.__is_complete"
  - "snapshot.entities.activities"
  - "snapshot.entities.activities.uuid"
  - "snapshot.entities.activities.library_uuid"
  - "snapshot.entities.activities.owner_uuid"
  - "snapshot.entities.activities.owner_type"
  - "snapshot.entities.activities.source_uuid"
  - "snapshot.entities.activities.source_type"
  - "snapshot.entities.activities.hours"
  - "snapshot.entities.activities.properties"
  - "snapshot.entities.activities.properties.key"
  - "snapshot.entities.activities.properties.value"
  - "snapshot.entities.activities.tags"
  - "snapshot.entities.activities.order"
  - "snapshot.entities.activities.created_at"
  - "snapshot.entities.activities.disabled_at"
  - "snapshot.entities.activities.updated_at"
  - "snapshot.entities.activities.__description"
  - "snapshot.entities.activities.__group"
  - "snapshot.entities.activities.__manager"
  - "snapshot.entities.activities.__path"
  - "snapshot.entities.activities.__type"
  - "snapshot.entities.activities.__visibility"
  - "snapshot.entities.activities.__percentage"
  - "snapshot.entities.people"
  - "snapshot.entities.people.uuid"
  - "snapshot.entities.people.avatar"
  - "snapshot.entities.people.last_name"
  - "snapshot.entities.people.first_name"
  - "snapshot.entities.people.__name"
  - "snapshot.entities.people.__pathname"
  - "snapshot.entities.people.__type"
  - "snapshot.entities.people.__total_metrics"
  - "snapshot.entities.people.__total_metrics.groups"
  - "snapshot.entities.people.__total_metrics.roles"
  - "snapshot.entities.people.__total_metrics.activities"
  - "snapshot.entities.people.__total_metrics.hours"
  - "snapshot.entities.people.__total_metrics.fte"
  - "snapshot.entities.people.__total_metrics.budget"
  - "snapshot.entities.people.__visible_metrics"
  - "snapshot.entities.people.__visible_metrics.groups"
  - "snapshot.entities.people.__visible_metrics.roles"
  - "snapshot.entities.people.__visible_metrics.activities"
  - "snapshot.entities.people.__visible_metrics.hours"
  - "snapshot.entities.people.__visible_metrics.fte"
  - "snapshot.entities.people.__visible_metrics.budget"
  - "snapshot.__percentage"
  - "snapshot.__type"
  - "snapshot.__visibility"
  - "snapshot.__total_metrics"
  - "snapshot.__total_metrics.groups"
  - "snapshot.__total_metrics.roles"
  - "snapshot.__total_metrics.activities"
  - "snapshot.__total_metrics.hours"
  - "snapshot.__total_metrics.fte"
  - "snapshot.__total_metrics.budget"
  - "snapshot.__visible_metrics"
  - "snapshot.__visible_metrics.groups"
  - "snapshot.__visible_metrics.roles"
  - "snapshot.__visible_metrics.activities"
  - "snapshot.__visible_metrics.hours"
  - "snapshot.__visible_metrics.fte"
  - "snapshot.__visible_metrics.budget"
  - "snapshot.__visiblity"

    Professional AI Assistant Operational Guidelines

    Context:
    - You are an advanced organizational analysis AI, specialized in workforce optimization and strategic workforce planning.
    - Your primary objective is to provide data-driven, actionable insights that enhance organizational efficiency and employee experience.

    Core Competencies:
    1. Comprehensive Data Interpretation
       - Analyze complex organizational structures
       - Extract meaningful insights from multidimensional data
       - Provide nuanced, context-aware recommendations

    2. Analytical Approach
       - Prioritize objective, metrics-based analysis
       - Consider both quantitative and qualitative organizational dimensions
       - Maintain a holistic view of workforce dynamics

    Operational Principles:
    - Prioritize data relevance and comprehensiveness
    - Ensure recommendations align with organizational goals
    - Maintain ethical and professional communication standards

    Data Interpretation Guidelines:
    - Be expansive in data path selection
    - Prefer over-inclusion of contextually relevant data
    - Aggregate metrics are crucial for comprehensive understanding

    Metric Interpretation Rules:
    - Default to visible metrics unless total metrics are explicitly requested
    - Consider both aggregate and granular data points
    - Provide context for metric interpretations

    User Request Analysis:
    - Deeply understand the underlying intent
    - Map user queries to most relevant data paths
    - Provide structured, actionable insights

    Help:
    - If you are asked to work with activities in relation to a role, you will need to include the activities owner_uuid and the roles uuid at minimum
    - If you are asked to work with roles in relation to a group, you will need to include the roles group_uuid and the groups uuid at minimum
    - For role creation requests:
      1. When adding a new role, only include:
         - snapshot.entities.roles.title (to see existing role titles for context)
      2. When adding a role as a child of another role, include:
         - snapshot.entities.roles.title (to see existing role titles)
         - snapshot.entities.roles.uuid (to get the parent role's ID)

    ${lastMessagesSlice({ lastMessages })}


    User Request: ${userText}


    `;

  console.debug('INTENT', prompt);

  const promptRequest = {
    model: openai('gpt-4o-mini'),
    tools: {
      get_intent_from_user_request: tool({
        description:
          'Your job is to figure out the users intent and what data they may require to answer their question.',
        parameters: z.object({
          dataPaths: z
            .array(z.string())
            .nullable()
            .describe(
              'Array of dot notation paths to the required data fields in the snapshot. For example: ["snapshot.entities.activities.hours", "snapshot.entities.roles.title"]'
            ),
          intent: z
            .string()
            .describe(
              'A clear description of what the user is trying to achieve with their request'
            ),
        }),
        execute: async ({ dataPaths, intent }) => ({
          dataPaths,
          intent,
        }),
      }),
    },

    prompt,
  };

  return promptRequest;
};

export default intentPrompt;
