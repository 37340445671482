export default function defaultMetricGroup() {
  return {
    groups: 0,
    roles: 0,
    activities: 0,
    hours: 0,
    fte: 0,
    budget: 0,
  };
}
